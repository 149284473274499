// This file can be replaced during build by using the fileReplacements array.
// ng build ---prod replaces environment.ts with environment.prod.ts.
// The list of file replacements can be found in angular.json.

export const environment = {
  production: true,


  ONBOARDING_BASE_API_URL: 'https://dev.api.eatsobvious.com/v1/common',

  BASE_API_URL: 'https://dev.api.eatsobvious.com',

  AGENT_BASE_API_URL: 'https://dev.agent.eatsobvious.com',

  ONBOARDING_TRACK_BASE_URL: 'https://dev.api.eatsobvious.com',

  BASE_IMAGE_URL: 'https://eatsobvious.imgix.net',

  DIALOGFLOW_API_URL_V2: 'https://dialogflow.googleapis.com/v2',

  INSTANCE_SELECTION: false,

  CYBERSOURCE_API_URL: 'https://api.cybersource.com/flex/v1/tokens',

  CONVERGEPAY_API_URL: 'https://api.convergepay.com/hosted-payments',

  DB_SECRET: 'd1fda7d1a7680267aaefb8d6c8c320b5'

};

/*
 * In development mode, to ignore zone related error stack frames such as
 * zone.run, zoneDelegate.invokeTask for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
